import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageHeader from '../components/PageHeader'
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle,
} from 'reactstrap';
import { Helmet } from "react-helmet"

const News = ({data}) => {

  const allpost = data.allSanityPost.edges;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>News - IMSSDARM Asian Division</title>
        <link rel="canonical" href="https://imssdarm.asia/news" />
        <meta name="description" content="The church is God’s appointed agency for the salvation of men"></meta>
      </Helmet>
      <div className="news">
        <PageHeader>
          <h1>News</h1>
        </PageHeader>
        <div className="container py-5">
          <div className="row">
            {allpost.map((blog, idx) => (
              <div className="col-md-4">
                  <Card>
                    <CardBody>
                      <CardImg top
                        src={blog.node.mainImage.asset.url}
                        alt={blog.node.title}
                      />
                      <div className="info">
                        <CardTitle tag="h3">
                          <Link to={`/${blog.node.slug.current}`}>{blog.node.title}</Link>
                        </CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">{blog.node._updatedAt}</CardSubtitle>
                        <CardText>{blog.node.body}</CardText>
                        <a href={blog.node.slug.current} className="btn btn-primary">Read more</a>
                      </div>
                    </CardBody>
                  </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityPost {
      edges {
        node {
          id
          mainImage {
            asset {
               url
            }
          }
          title
          slug {
            current
          }
          _updatedAt(formatString: "MMM DD, yyyy")
          body
        }
      }
    }
  }
`

export default News;
